//
//== DEFAULTS RAILS
  import Rails from "@rails/ujs"
  Rails.start()

//
//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

//
//== BOOTSTRAP
  import { Collapse, Modal, Tooltip } from 'bootstrap'

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })


//
//== FANCYBOX
  import { Fancybox } from "@fancyapps/ui";

//
//== MOBILE MENUE
  $('#navbar_main ul li.dropdown > a').click(function(e) {
    if ($(window).width() <= 991.98){
      if ($(this).next('ul').is(':hidden') ) {
        $('#navbar_main ul li.dropdown ul').hide();
        $(this).next('ul').show();
      } else {
        $(this).next('ul').hide();
      }

      e.preventDefault();
    }
  });


//
//== SCROLL TOP
  window.addEventListener("scroll", function(){
    let scroll = document.querySelector('.scroll-to-top');
    scroll.classList.toggle("active", window.scrollY > 500)
  })




